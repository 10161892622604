<template>
  <FullScreenDialog
    v-model="localDialog"
    title="Pagamento"
    without-padding
    @input="handleFullScreenDialogChange"
    :can-close="canCloseDialog"
  >
    <template v-slot:content>
      <template v-if="cashDeskOpened">
        <v-stepper v-model="currentStep" height="100%">
          <v-stepper-header>
            <template v-for="(step, index) in steps">
              <v-stepper-step
                :key="step.name"
                :complete="currentStep > index + 1"
                :step="index + 1"
                :editable="currentStep > index + 1 && canChangeStep"
                :disabled="!canChangeStep"
                @click="handleStepClicked(index + 1)"
                edit-icon="mdi-check"
                v-ripple="false"
              >
                {{step.title}}
                <div class="text-caption">{{step.subtitle}}</div>
              </v-stepper-step>
              <v-divider :key="step.name + 'divider'" v-if="index != steps.length - 1"></v-divider>
            </template>
          </v-stepper-header>

          <v-stepper-items>
            <template v-for="(step, index) in steps">
              <v-stepper-content 
                :step="index + 1"
                :key="step.name"
                v-if="step.name == 'invoiceOrReceipt'"
                class="pa-0"
              >
                <div
                  style="max-height: 70vh; height: 800px; width: 100%; overflow: auto"
                  class="d-flex justify-center align-center flex-wrap py-3"
                >
                  <v-card 
                    class="text-h6 d-flex align-center justify-center"
                    height="200"
                    width="200"
                    @click="handleInvoiceClick()"
                  >
                    Fattura
                  </v-card>
                  <v-card
                    class="text-h6 d-flex align-center justify-center ml-2"
                    height="200"
                    width="200"
                    @click="handleReceiptClick()"
                  >
                    Scontrino
                  </v-card>
                </div>
              </v-stepper-content>
              <v-stepper-content
                :step="index + 1"
                v-else-if="step.name == 'invoiceData'"
                :key="step.name"
                class="pa-0"
              >
                <div
                  style="max-height: calc(100vh - 64px - 72px - 52px - 20px); height: 800px; width: 100%; overflow: auto"
                >
                  <div class="d-flex justify-center">
                    <InvoiceDataForm
                      :customer="customer"
                      v-model="invoiceData"
                      :valid.sync="invoiceFormValid"
                      style="max-width: 1000px"
                    ></InvoiceDataForm>
                  </div>
                  <div class="mt-3 d-flex justify-center">
                    <v-btn text color="error" @click="goBackToStep(currentStep - 1)">
                      <v-icon left>mdi-arrow-left</v-icon>
                      Indietro
                    </v-btn>
                    <v-btn 
                      v-if="!!customer && !!invoiceData"
                      text 
                      color="default"
                      :loading="loadingCustomerUpdate"
                      @click="updateCustomerDataInvoice"
                    >
                      <v-icon left>mdi-content-save</v-icon>
                      Salva sul cliente
                    </v-btn>
                    <v-btn text color="success" @click="confirmInvoiceData" 
                      :disabled="!invoiceFormValid 
                        || (!savedInfo && !!customer && !!customer.dataInvoice && !!customer.dataInvoice.cap)">
                      <v-icon left>mdi-check</v-icon>
                      Conferma
                    </v-btn>
                  </div>
                  <div class="d-flex justify-center">
                    <v-alert 
                      v-if="dataAlert" 
                      class="ma-2" 
                      dense 
                      outlined 
                      type="error" 
                      style="width: 400px"
                    >
                      Inserire almeno un dato tra:
                      <ul>
                        <li>Ragione Sociale o Nome</li>
                        <li>Email Pec o Codice Univoco Destinatario</li>
                      </ul>
                      Tutti gli altri dati sono obbligatori!
                    </v-alert>
                  </div>
                </div>
              </v-stepper-content>
              <v-stepper-content 
                :step="index + 1"
                :key="step.name"
                v-else-if="step.name == 'summary'"
                class="pa-0"
              >
                <div
                  style="max-height: calc(100vh - 64px - 72px - 52px - 20px); height: 800px; width: 100%;"
                >
                  <PaymentSummary
                    ref="paymentSummary"
                    :total="totalValue"
                    :discount="discountOnTotal"
                    :card-subtract="cardSubtract"
                    :provided-entities="providedEverything"
                    :local-bill="localBill"
                    :item-price="handleItemPrice"
                    :item-discount="handleItemDiscount"
                    :item-name="(anything) => {
                      if(!!anything.service) {
                        return anything.service.name
                      } else if(!!anything.item) {
                        return anything.item.description
                      } else {
                        return 0
                      }
                    }"
                    :opened-cash-desk="openedCashDesk"
                    @confirm="goToStep(currentStep + 1)"
                    @go-back="goBackToStep(currentStep - 1)"
                    :hide-paid-amounts="invoiceOrReceipt == 'payLater'"
                    :paid-with-cash.sync="paidWithCash"
                    :paid-with-card.sync="paidWithCard"
                    :paid-with-tickets.sync="paidWithTickets"
                    :paid-with-check.sync="paidWithCheck"
                    :not-paid.sync="notPaid"
                    :to-be-paid.sync="toBePaid"
                    :total-paid.sync="totalPaid"
                    :invoice-or-receipt="invoiceOrReceipt"
                    :customer="customer"
                    :invoice-data="invoiceData"
                    :lottery-code.sync="lotteryCode"
                    :use-lottery-code.sync="useLotteryCode"
                  ></PaymentSummary>
                </div>
              </v-stepper-content>
              <v-stepper-content 
                :step="index + 1"
                :key="step.name"
                v-else-if="step.name == 'payment'"
              >
                <div
                  style="height: 70vh;"
                  class="d-flex flex-column justify-center align-center flex-wrap"
                >
                  <div class="d-flex justify-center align-center my-2">
                    <AnimatedCheck
                      class="mx-4"
                      v-model="invoicePrinted"
                    ></AnimatedCheck>
                    <div class="text-h6 text-center" style="width: 500px; max-width: 90vw">
                      {{invoicePrintedMessage}}
                    </div>
                  </div>
                  <div class="d-flex justify-center align-center my-2">
                    <AnimatedCheck
                      class="mx-4"
                      v-model="dataRegistered"
                    ></AnimatedCheck>
                    <div class="text-h6 text-center" style="width: 500px; max-width: 90vw">
                      {{dataRegisteredMessage}}
                    </div>
                  </div>
                  <div class="d-flex justify-center align-center my-2">
                    <AnimatedCheck
                      class="mx-4"
                      v-model="billClosed"
                    ></AnimatedCheck>
                    <div class="text-h6 text-center" style="width: 500px; max-width: 90vw">
                      {{billClosedMessage}}
                    </div>
                  </div>
                  <div class="d-flex justify-center align-center mt-5">
                    <v-btn icon @click="closeDialog" :disabled="canCloseDialog"></v-btn>
                  </div>
                </div>
              </v-stepper-content>
            </template>
          </v-stepper-items>
        </v-stepper>
      </template>
      <template v-else>
        Non puoi procedere con la cassa chiusa
      </template>
    </template>
  </FullScreenDialog>
</template>

<script>
import cashDeskService from '@/services/cashDesks/cashDesks.service.js'
import StandardDialog from '@/components/common/StandardDialog'
import FullScreenDialog from '@/components/common/FullScreenDialog'
import billService from '@/services/bills/bills.service.js'
import billServiceCalculator from '@/services/bills/billService.calculator.js'
import OperatorAvatar from '@/components/common/OperatorAvatar.vue';
import NumericKeyboardInput from '@/components/common/NumericKeyboardInput.vue';
import AnimatedCheck from '@/components/common/AnimatedCheck.vue';
import PaymentSummary from '@/components/homepage/sections/PaymentSummary.vue'
import InvoiceDataForm from '@/components/homepage/sections/InvoiceDataForm.vue'
import customerService from '@/services/customers/customers.service.js'
import settingService from "@/services/settings/settings.service.js";
import cashDialogMixin from "@/mixins/bills/cashDialog.mixin.js";
import SuppliersAutocomplete from "@/components/common/SuppliersAutocomplete.vue";
import { cloneDeep } from 'lodash'

export default {
  name: "CashDialog",
  mixins: [cashDialogMixin],
  components: {
    StandardDialog,
    FullScreenDialog,
    OperatorAvatar,
    NumericKeyboardInput,
    AnimatedCheck,
    PaymentSummary,
    SuppliersAutocomplete,
    InvoiceDataForm
  },
  data: function() {
    return {
      localBill: undefined,
      localDialog: this.dialog,
      currentStep: 1,
      invoiceOrReceipt: undefined,
      totalValue: undefined,
      discountOnTotal: undefined,
      openedCashDesk: undefined,
      paidWithCash: 0,
      paidWithCard: 0,
      paidWithTickets: 0,
      paidWithCheck: 0,
      notPaid: 0,
      cardSubtract: 0,
      loadingCustomerUpdate: false,
      billClosed: false,
      dataRegistered: false,
      invoicePrinted: false,
      canCloseDialog: true,
      canChangeStep: true,
      localCustomerCard: undefined,
      billServiceCalculatorUpdateHandler: undefined,
      invoiceData: undefined,
      invoiceFormValid: false,
      totalPaid: undefined,
      discountedBillServices: [],
      discountedBillItems: [],
      discountedBillEntities: [],
      customerCardEntities: undefined,
      dataAlert: false,
      typeCredit: undefined,
      remaining: undefined,
      whatsappNumber: undefined,
      ctrlGoBack: false,
      lotteryCode: undefined,
      useLotteryCode: undefined,
      taxForSaloon: undefined,
      savedInfo: false,
      toBePaid: 0,
    }
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "beauty"
    },
    goBack: {
      type: Boolean,
      default: false
    },
    goBackTo: { },
    billId: { },
    bill: { },
    promoUsed: {
    },
    promoRemaining: {
    },
    useCustomerCard: {
      type: Boolean,
      default: false
    },
    usePromos: {
      type: Boolean,
      default: false
    },
    tableId: {
      type: Number,
      default: undefined,
    },
    partialPayment: {
      type: Boolean,
      default: false,
    },
    cashDeskLog: {},
    returnBill:{
      default: undefined
    },
    customerCardCreditGhost: {
      default: false
    },
  },
  mounted: function() {
    this.loadSettings();
    this.billServiceCalculatorUpdateHandler = () => {
      this.totalValue = Number(billServiceCalculator.toBePaid)
      this.discountOnTotal = Number(billServiceCalculator.discount)
      this.cardSubtract = Number(billServiceCalculator.cardSubtract)
      this.discountedBillServices = cloneDeep(billServiceCalculator.discountedBillServices)
      this.discountedBillItems = cloneDeep(billServiceCalculator.discountedBillItems)
      this.discountedBillEntities = cloneDeep(billServiceCalculator.discountedBillEntities)
      this.customerCardEntities = billServiceCalculator.customerCardEntities
      this.remaining = billServiceCalculator.remainingPromo
    }
    this.billServiceCalculatorUpdateHandler = this.billServiceCalculatorUpdateHandler.bind(this)
    this.billServiceCalculatorUpdateHandler()
    billServiceCalculator.on('update', this.billServiceCalculatorUpdateHandler)

    billService.get({id: this.billId}).then((bill) => {
      this.localBill = bill
      this.initializeBillCalculator()
    })

    this.getOpenedCashDesk()
  },
  methods: {
    closeDialog() {
      if(!this.ctrlGoBack){
        this.$emit('update:dialog', false)
        let goBack;
        if(!!this.goBack) {
          goBack = () => {
            this.$router.go(-1)
          }
        } else if(!!this.goBackTo) {
          let goBackTo = {...this.goBackTo}
          if(this.billClosed && this.dataRegistered && this.invoicePrinted && !!goBackTo.params) goBackTo.params.selectedBill = !!this.returnBill ? this.returnBill : undefined
          goBack = () => {
            this.$router.push(goBackTo)
          }
        }
        this.ctrlGoBack = true
        setTimeout(goBack.bind(this), 200);
      }
    },
    getOpenedCashDesk() {
      cashDeskService.getOpened().then((openedCashDesk) => {
        this.openedCashDesk = openedCashDesk
      })
    },
    initializeBillCalculator() {
      billServiceCalculator.setCustomerCard(this.activeCard)
      let promos = !!this.promoUsed ? [this.promoUsed] : this.activePromosCustomers
      billServiceCalculator.setPromosCustomers(promos)
      if(!!this.localBill) {
        billServiceCalculator.setBill(this.localBill)
      }
      billServiceCalculator.useCard(this.useCustomerCard)
      billServiceCalculator.usePromos(this.usePromos)
    },
    handleFullScreenDialogChange(newVal) {
      if(!newVal) {
        this.closeDialog()
      }
    },
    startFromScratch() {
      this.invoiceOrReceipt = undefined
      this.currentStep = 1
    },
    setInvoiceOrReceipt(type) {
      this.invoiceOrReceipt = type
    },
    goToStep(number) {
      const step = this.steps[number - 1]
      if(step.name == 'summary') {
        this.currentStep = number
        setTimeout(() => {
          this.$refs.paymentSummary[0].calculateScrollReceipt()
        }, 200)
        this.dataAlert = false
      } else if(step.name == 'payment') {
        if(this.toBePaid == 0) {
          setTimeout(() => {
            this.pay()
          }, 1000);
        }
      }
      if(!this.dataAlert) this.currentStep = number
    },
    goBackToStep(number) {
      const step = this.steps[number - 1]
      if(step.name == 'invoiceOrReceipt') {
        this.invoiceOrReceipt = undefined
      }

      this.goToStep(number)
    },
    handleStepClicked(number) {
      if(this.currentStep == number || !this.canChangeStep) {
        return
      } else if(this.currentStep < number) {
        this.$delegates.snackbar("Confermare prima di avanzare")
      } else {
        this.goBackToStep(number)
      }
    },
    handleInvoiceClick() {
      this.setInvoiceOrReceipt('invoice');
      this.$nextTick(() => {
        this.goToStep(this.currentStep + 1)
      })
    },
    handleReceiptClick() {
      this.setInvoiceOrReceipt('receipt');
      this.$nextTick(() => {
        if(this.currentStep == 1)
          this.goToStep(this.currentStep + 1)
      })
    },
    loadSettings: async function () {
      settingService.list("company").then((list) => {
        let settings = [];
        for (const [key, value] of Object.entries(list)) {
          settings.push({
            key: key,
            value: value.value,
          });
        }
        this.settings = settings;
      });
    },
    confirmInvoiceData() {
      if((!this.invoiceData.companyNameOrLastname && !this.invoiceData.firstname) || 
          (!this.invoiceData.destinataryCode && !this.invoiceData.mail) ||
          (!this.invoiceData.address) || 
          (!this.invoiceData.cap) ||
          (!this.invoiceData.city) ||
          (!this.invoiceData.fiscalCode) ||
          (!this.invoiceData.vatNumber) 
        )
          this.dataAlert = true
       {
        if(!!this.openedCashDesk.operator)
          this.invoiceData.operatorId = this.openedCashDesk.operator.id
        this.goToStep(this.currentStep + 1)
      }
    },
    handleItemPrice(anything) {
      if(!!anything.service) {
        if (!!anything.editPriceType && !!anything.editPriceValue)
          return billServiceCalculator.calculateEditedPrice(anything)
        else
          return anything.service.price
      } else if(!!anything.item) {
        if (!!anything.editPriceType && !!anything.editPriceValue)
          return billServiceCalculator.calculateEditedItemPrice(anything)
        else
          return anything.item.priceSelling
      } else {
        return 0
      }
    },
    handleItemDiscount(anything) {
      if (!!anything.service)
        return billServiceCalculator.calculateDiscountShot(anything, anything.discount)
      else if (!!anything.item)
        return billServiceCalculator.calculateDiscountShotItem(anything, anything.discount)
      else
        return anything.discount
    },
    updateCustomerDataInvoice() {
      if(!!this.customer && !!this.invoiceData) {
        let dataInvoice = {...this.invoiceData}
        dataInvoice.lastname = dataInvoice.companyNameOrLastname
        delete dataInvoice.companyNameOrLastname

        this.loadingCustomerUpdate = true
        customerService.update({
          id: this.customer.id,
          dataInvoice: dataInvoice
        }).then((results) => {
          this.loadingCustomerUpdate = false
          this.savedInfo = true
          this.$delegates.snackbar('Salvataggio avvenuto correttamente')
        })
      }
      else{
        this.$delegates.snackbar('Salvataggio non riuscito')
      }
    }
  },
  watch: {
    dialog(newVal) {
      this.localDialog = newVal
      if(newVal) {
        this.startFromScratch()
      }
    },
  },
  computed: {
    customer() {
      if(!!this.localBill && !!this.localBill.billCustomers && this.localBill.billCustomers.length > 0) {
        return this.localBill.billCustomers[0].customer
      } else {
        return undefined
      }
    },
    steps() {
      let steps = []

      if(!this.invoiceOrReceipt) {
        steps.push({
          title: 'Scontrino o Fattura',
          name: "invoiceOrReceipt"
        })

        steps.push({
          title: 'Dati Fatturazione',
          name: 'invoiceData'
        })
      } else if(this.invoiceOrReceipt == 'invoice') {
        steps.push({
          title: 'Fattura',
          name: "invoiceOrReceipt"
        })

        steps.push({
          title: 'Dati Fatturazione',
          name: 'invoiceData'
        })
      } else if(this.invoiceOrReceipt == 'receipt') {
        steps.push({
          title: 'Scontrino',
          name: "invoiceOrReceipt"
        })
      }

      steps.push({
        title: 'Repilogo',
        name: 'summary'
      })

      steps.push({
        title: 'Pagamento',
        name: 'payment'
      })


      return steps
    },
    providedServices() {
      if(!this.localBill) {
        return []
      } else {
        return this.discountedBillServices
      }
    },
    providedItems() {
      if(!this.localBill) {
        return []
      } else {
        return this.discountedBillItems
      }
    },
    providedEverything() {
      if(!!this.customerCardEntities) {
        return this.discountedBillEntities
      } else {
        return [
          ...this.providedServices,
          ...this.providedItems
        ]
      }
    },
    activeCard() {
      if(!!this.localBill && !!this.localBill.billCustomers && !!this.localBill.billCustomers[0] && !!this.localBill.billCustomers[0].customer.activeCustomerCards && !!this.localBill.billCustomers[0].customer.activeCustomerCards[0]) {
        return this.localBill.billCustomers[0].customer.activeCustomerCards[0]
      } else {
        return undefined
      }
    },
    activePromosCustomers() {
      if(!!this.localBill && !!this.localBill.customer && !!this.localBill.customer.promosCustomers) {
        return this.localBill.customer.promosCustomers
      } else if(!!this.localBill && !!this.localBill.billCustomers && !!this.localBill.billCustomers[0] && !!this.localBill.billCustomers[0].customer && !!this.localBill.billCustomers[0].customer.promosCustomers) {
        return this.localBill.billCustomers[0].customer.promosCustomers
      } else {
        return undefined
      }
    },
    cashDeskOpened() {
      return !!this.openedCashDesk
    },
    billClosedMessage() {
      return this.billClosed ? 'Conto chiuso correttamente' : 'Chiusura del conto ...'
    },
    dataRegisteredMessage() {
      return this.dataRegistered ? 'Dati registrati correttamente' : 'Registrazione dei dati ...'
    },
    invoicePrintedMessage() {
      return this.invoicePrinted ? 'Scontrino stampato correttamente' : 'Stampa dello scontrino ...'
    }
  },
  settings: {
    whatsappNumber: {
      bind: 'whatsappNumber'
    },
    taxForSaloon: {
      bind: 'taxForSaloon'
    }
  }
}
</script>

<style>

</style>
import Printer from '@/services/devices/rtPrinter'
import billService from '@/services/bills/bills.service.js'
import billServiceCalculator from '@/services/bills/billService.calculator.js'
import customerCardService from '@/services/customers/customerCards.service.js'
import smsService from '@/services/sms/sms.service.js';
import marketingEventsService from '@/services/marketingEvents/marketingEvents.service.js';
import cookiesService from '../../services/cookies.service';
import cashDeskService from '@/services/cashDesks/cashDesks.service.js'

export default {
  methods: {
    // calculate an array of entities to pass to the rtPrinter
    calculateNotGhostedEntities() {
      const notGhostedServices = this.providedServices.map(billService => {
        let taxForSaloon = billService.tax !== undefined && billService.tax !== null ? billService.tax : undefined
        if (taxForSaloon === undefined || taxForSaloon === null) taxForSaloon = '22'

        let object = {
          name: billService.service.name.replace("&", "e"),
          price: Number(billService.service.price),
          quantity: billService.quantity,
          discount: !!Number(billService.discount) ? Number(billService.discount) : undefined,
          additionalDescription: !!billService.billServiceOperators && !!billService.billServiceOperators[0] ? billService.billServiceOperators[0].operator.firstname : "",
          discountDescription: billService.discount != '0.00' ? billService.discountDescription : undefined,
          tax: taxForSaloon + '%',
        }

        if (billService.ghost) {
          object.quantity = object.quantity == 1 ? 0 : object.quantity - billService.ghostQuantity
        }

        if (!!billService.editPriceType && !!billService.editPriceValue) {
          object.price = billServiceCalculator.calculateEditedPrice(billService)
          //if (!!object.discount)
          //object.discount = billServiceCalculator.calculateDiscountShot(billService, object.discount)
        }

        return object
      }).filter(el => !!el.quantity)

      const notGhostedItems = this.providedItems.map(billItem => {
        let taxForSaloon = billItem.tax !== undefined && billItem.tax !== null ? billItem.tax : undefined
        if (taxForSaloon === undefined || taxForSaloon === null) taxForSaloon = '22'

        let object = {
          name: billItem.item.description.replace("&", "e"),
          price: Number(billItem.item.priceSelling),
          quantity: billItem.quantity,
          discount: !!Number(billItem.discount) ? Number(billItem.discount) : undefined,
          additionalDescription: "",
          discountDescription: billItem.discount != 0 ? billItem.discountDescription : undefined,
          tax: taxForSaloon + '%',
        }

        if (billItem.ghost) {
          object.quantity = object.quantity == 1 ? 0 : object.quantity - billItem.ghostQuantity
        }

        if (!!billItem.editPriceType && !!billItem.editPriceValue) {
          object.price = billServiceCalculator.calculateEditedItemPrice(billItem)
          //if (!!object.discount)
          //object.discount = billServiceCalculator.calculateDiscountShotItem(billItem, object.discount)
        }

        return object
      }).filter(el => !!el.quantity)

      let notGhostedAnything = []
      if (this.useCustomerCard && notGhostedItems.length > 0 && notGhostedServices.length > 0) {
        notGhostedAnything = this.providedEverything.map(billEntity => {
          let taxForSaloon = billEntity.tax !== undefined && billEntity.tax !== null ? billEntity.tax : undefined
          if (taxForSaloon === undefined || taxForSaloon === null) taxForSaloon = '22'

          if (billEntity.type == 'item') {
            let object = {
              name: billEntity.item.description.replace("&", "e"),
              price: Number(billEntity.item.priceSelling),
              quantity: billEntity.quantity,
              discount: !!Number(billEntity.discount) ? Number(billEntity.discount) : undefined,
              additionalDescription: "",
              discountDescription: billEntity.discount != 0 ? billEntity.discountDescription : undefined,
              tax: taxForSaloon + '%',
            }

            if (billEntity.ghost) {
              object.quantity = object.quantity == 1 ? 0 : object.quantity - billEntity.ghostQuantity
            }

            if (!!billEntity.editPriceType && !!billEntity.editPriceValue) {
              object.price = billServiceCalculator.calculateEditedItemPrice(billEntity)
              //if (!!object.discount)
              //object.discount = billServiceCalculator.calculateDiscountShotItem(billEntity, object.discount)
            }

            return object
          }

          if (billEntity.type == 'service') {
            let object = {
              name: billEntity.service.name.replace("&", "e"),
              price: Number(billEntity.service.price),
              quantity: billEntity.quantity,
              discount: billEntity.discount != '0.00' ? billEntity.discount : undefined,
              additionalDescription: billEntity.billServiceOperators[0] ? billEntity.billServiceOperators[0].operator.firstname : "",
              discountDescription: billEntity.discount != '0.00' ? billEntity.discountDescription : undefined,
              // tax: billService.service.departments.some(d=> d.description == 'Bar')  ? "10%" :"22%"
              tax: taxForSaloon + '%',
            }

            if (billEntity.ghost) {
              object.quantity = object.quantity == 1 ? 0 : object.quantity - billEntity.ghostQuantity
            }

            if (!!billEntity.editPriceType && !!billEntity.editPriceValue) {
              object.price = billServiceCalculator.calculateEditedPrice(billEntity)
            }

            return object
          }
        }).filter(el => !!el.quantity)
      } else {
        notGhostedAnything = [
          ...notGhostedServices,
          ...notGhostedItems
        ]
      }

      return {
        notGhostedAnything,
        notGhostedItems,
        notGhostedServices
      }
    },

    // calculate an array of payment type with amount and type
    calculatePaymentTypes() {
      let paymentTypes = []
      if (!!this.paidWithCash) {
        paymentTypes.push({
          amount: this.paidWithCash,
          type: 'cash'
        })
      }

      if (!!this.paidWithCard) {
        paymentTypes.push({
          amount: this.paidWithCard,
          type: 'card'
        })
      }

      if (!!this.notPaid) {
        this.typeCredit = true
        paymentTypes.push({
          amount: this.notPaid,
          type: 'credit'
        })
      }

      if (!!this.paidWithTickets) {
        paymentTypes.push({
          amount: this.paidWithTickets,
          type: 'ticket'
        })
      }

      if (!!this.paidWithCheck) {
        paymentTypes.push({
          amount: this.paidWithCheck,
          type: 'cheque'
        })
      }

      if (paymentTypes.length == 0) {
        paymentTypes.push({
          amount: 0,
          type: 'cash'
        })
      }

      return paymentTypes
    },

    // send the XML to the RT
    async sendToRtPrinter({ notGhostedAnything, notGhostedServices, notGhostedItems, paymentTypes, transaction }) {
      let rtResponse

      if (this.invoiceOrReceipt == 'receipt' && notGhostedAnything.length > 0) {
        if (!!this.typeCredit) {
          if (notGhostedServices.length > 0) {
            let totalService = notGhostedServices.reduce((acc, item) => {
              return acc + ((Number(item.price) * Number(item.quantity)) - (Number(item.discount) || 0))
            }, 0)

            rtResponse = await Printer.printSummary({
              amount: Number(totalService) - (Number(billServiceCalculator.discountOnTotal) || 0),
              items: notGhostedServices,
              additionalRows: [
                "Non riscosso: " + (Number(billServiceCalculator.servicesTotal))
              ]
            }, false, "summary, receipt, typeCredit, notGhostedServices")
          }

          if (notGhostedItems.length > 0) {
            let totalItem = notGhostedItems.reduce((acc, item) => {
              return acc + ((Number(item.price) * Number(item.quantity)) - (Number(item.discount) || 0))
            }, 0)

            rtResponse = await Printer.printFiscalReceipt({
              paymentTypes: paymentTypes,
              amount: totalItem,
              items: notGhostedItems,
              discounts: undefined,
              lotteryCode: this.useLotteryCode ? this.lotteryCode : undefined,
              additionalRows: [],
              transactionId: !!transaction ? transaction.id : undefined
            }, "fiscal, receipt, typeCredit, notGhostedItems")
          }
        } else if (!this.typeCredit && this.usePromos && (this.remaining !== undefined && this.remaining !== null)) {

          rtResponse = await Printer.printFiscalReceipt({
            paymentTypes: paymentTypes,
            items: notGhostedAnything,
            discounts: [
              {
                description: 'Promozione Utilizzata',
                amount: Number(billServiceCalculator._getPromosDiscount().min || billServiceCalculator._getPromosDiscount()),
                tax: notGhostedAnything[0].tax
              }
            ],
            lotteryCode: this.useLotteryCode ? this.lotteryCode : undefined,
            additionalRows: [
              'Sconto/Promozione Utilizzato: ' + Number(billServiceCalculator._getPromosDiscount().min || billServiceCalculator._getPromosDiscount()) + ' €',
              'Credito Rimasto: ' + this.remaining + ' €'
            ],
            transactionId: !!transaction ? transaction.id : undefined
          }, "fiscal, receipt, not typeCredit, usePromos, remaining")
        } else if (!this.typeCredit && this.usePromos && !this.remaining) {
          
          rtResponse = await Printer.printFiscalReceipt({
            paymentTypes: paymentTypes,
            items: notGhostedAnything,
            discounts: undefined,
            lotteryCode: this.useLotteryCode ? this.lotteryCode : undefined,
            additionalRows: ['Sconto/Promozione Utilizzata: ' + Number(billServiceCalculator._getPromosDiscount().min || billServiceCalculator._getPromosDiscount()) + ' €'],
            transactionId: !!transaction ? transaction.id : undefined
          }, "fiscal, receipt, not typeCredit, usePromos, not remaining")
        } else {
          if (!this.customerCardCreditGhost || !this.useCustomerCard) {

            rtResponse = await Printer.printFiscalReceipt({
              paymentTypes: paymentTypes,
              items: notGhostedAnything,
              discounts: this.useCustomerCard ? [
                {
                  description: 'Credito tessera utilizzato',
                  amount: billServiceCalculator.cardSubtract,
                  tax: notGhostedAnything[0].tax
                },
              ] : undefined,
              lotteryCode: this.useLotteryCode ? this.lotteryCode : undefined,
              additionalRows: this.useCustomerCard ?
                [
                  'Riepilogo Uso Tessera: ',
                  'Credito Precedente: ' + billServiceCalculator.cardRemaining + ' €',
                  !!billServiceCalculator.discount ? 'Tot. Risparmi e Sconti: ' + billServiceCalculator.discount + ' €' : 'Tot. Risparmi e Sconti: 0 €',
                  'Credito Utilizzato: ' + billServiceCalculator.cardSubtract + ' €',
                  'Credito Attuale: ' + billServiceCalculator.newCardRemaining + ' €',
                  !!billServiceCalculator._customerCard.lastLoadPaymentTransaction ? 'Documento comm.le: '
                    + (billServiceCalculator._customerCard.lastLoadPaymentTransaction.info.documentNumber === undefined ? 'non specificato' : billServiceCalculator._customerCard.lastLoadPaymentTransaction.info.documentNumber)
                    + ' del '
                    + new Date(billServiceCalculator._customerCard.lastLoadPaymentTransaction.date).toLocaleDateString('it-IT') : '',
                ] : [],
              transactionId: !!transaction ? transaction.id : undefined
            }, "fiscal, receipt, not typeCredit, not usePromos, not customerCardCreditGhost")
          }
          else if (this.customerCardCreditGhost && this.useCustomerCard && 
              paymentTypes.filter(pt => pt.amount > 0).length > 0) {
              
            rtResponse = await Printer.printFiscalReceipt({
              amount: billServiceCalculator.toBePaid,
              paymentTypes: paymentTypes,
              items: notGhostedAnything,
              discounts: this.useCustomerCard ? [
                {
                  description: 'Credito tessera utilizzato',
                  amount: billServiceCalculator.cardSubtract,
                  tax: notGhostedAnything[0].tax
                },
              ] : undefined,
              additionalRows: this.useCustomerCard ?
                [
                  'Riepilogo Uso Tessera: ',
                  'Credito Precedente: ' + billServiceCalculator.cardRemaining + ' €',
                  !!billServiceCalculator.discount ? 'Tot. Risparmi e Sconti: ' + billServiceCalculator.discount + ' €' : 'Tot. Risparmi e Sconti: 0 €',
                  'Credito Utilizzato: ' + billServiceCalculator.cardSubtract + ' €',
                  'Credito Attuale: ' + billServiceCalculator.newCardRemaining + ' €',
                  !!billServiceCalculator._customerCard.lastLoadPaymentTransaction ? 'Documento comm.le: '
                    + (billServiceCalculator._customerCard.lastLoadPaymentTransaction.info.documentNumber === undefined ? 'non specificato' : billServiceCalculator._customerCard.lastLoadPaymentTransaction.info.documentNumber)
                    + ' del '
                    + new Date(billServiceCalculator._customerCard.lastLoadPaymentTransaction.date).toLocaleDateString('it-IT') : '',
                ] : [],
            }, "fiscal, receipt, not typeCredit, not usePromos, customerCardCreditGhost")

            rtResponse = await Printer.printSummary({
              amount: billServiceCalculator.cardSubtract,
              items: [
                {
                  name: 'Credito tessera utilizzato',
                  price: billServiceCalculator.cardSubtract,
                  quantity: 1,
                  discount: undefined,
                  additionalDescription: "",
                  discountDescription: undefined,
                  tax: notGhostedAnything[0].tax,
                }
              ],
            }, undefined, "summary, receipt, not typeCredit, not usePromos, customerCardCreditGhost, cardSubtract")
          } else {

            rtResponse = await Printer.printSummary({
              amount: billServiceCalculator.toBePaid,
              paymentTypes: paymentTypes,
              items: notGhostedAnything,
              discounts: this.useCustomerCard ? [
                {
                  description: 'Credito tessera utilizzato',
                  amount: billServiceCalculator.cardSubtract,
                  tax: notGhostedAnything[0].tax
                },
              ] : undefined,
              additionalRows: this.useCustomerCard ?
                [
                  'Riepilogo Uso Tessera: ',
                  'Credito Precedente: ' + billServiceCalculator.cardRemaining + ' €',
                  !!billServiceCalculator.discount ? 'Tot. Risparmi e Sconti: ' + billServiceCalculator.discount + ' €' : 'Tot. Risparmi e Sconti: 0 €',
                  'Credito Utilizzato: ' + billServiceCalculator.cardSubtract + ' €',
                  'Credito Attuale: ' + billServiceCalculator.newCardRemaining + ' €',
                  !!billServiceCalculator._customerCard.lastLoadPaymentTransaction ? 'Documento comm.le: '
                    + (billServiceCalculator._customerCard.lastLoadPaymentTransaction.info.documentNumber === undefined ? 'non specificato' : billServiceCalculator._customerCard.lastLoadPaymentTransaction.info.documentNumber)
                    + ' del '
                    + new Date(billServiceCalculator._customerCard.lastLoadPaymentTransaction.date).toLocaleDateString('it-IT') : '',
                ] : [],
            }, undefined, "summary, receipt, not typeCredit, not usePromos, not customerCardCreditGhost")
          }
        } 
      } else if (this.invoiceOrReceipt == 'invoice') {

        // copia cliente
        rtResponse = await Printer.printSummary({
          amount: billServiceCalculator.subTotal,
          invoiceData: this.invoiceData,
          items: notGhostedAnything
        }, true, "summary, invoice, not typeCredit, not usePromos, not customerCardCreditGhost, cliente")

        // copia salone
        rtResponse = await Printer.printSummary({
          amount: billServiceCalculator.subTotal,
          invoiceData: this.invoiceData,
          items: notGhostedAnything
        }, true, "summary, invoice, not typeCredit, not usePromos, not customerCardCreditGhost, salone")
      }

      return rtResponse
    },

    // send SMS that informs about the remaining on the customer card
    async sendSMS() {
      if (!!this.useCustomerCard) {
        try {
          let customerCardRemainingMessage = `Gentile ${this.customer.firstname} ${this.customer.lastname} il credito della sua tessera è di ${billServiceCalculator.newCardRemaining} €`;
          let phoneNumbers = [];
          phoneNumbers.push(this.customer.prefixCellphone + this.customer.cellphone)
          if (this.customer.sendByWhatsapp == true) {
            phoneNumbers[0] = phoneNumbers[0].replace("+", "")
            await smsService.sendWhatsappMessage(customerCardRemainingMessage, this.whatsappNumber, phoneNumbers[0], this.customer.id)
            this.$delegates.snackbar("Messaggio Whatsapp inviato con successo");
          } else {
            await smsService.sendSmsEsendex(customerCardRemainingMessage, phoneNumbers, this.customer.id)
            this.$delegates.snackbar("Sms inviato con successo");
          }
        } catch (exception) {
          // logging the exception for debug purpose
          // KEEP LOG
          console.log(exception)

          // if something goes wrong manually tell the user
          this.$delegates.snackbar("Errore durante l'invio del messaggio");
        }
      }
    },

    // close bill 
    async closeBill({ rtResponse }) {
      const cashDesk = await cashDeskService.getOpened()

      this.localBill.billServices = billServiceCalculator.discountedBillServices
      this.localBill.billItems = billServiceCalculator.discountedBillItems

      let res;
      if (this.invoiceOrReceipt == 'receipt') {
        if (this.typeCredit == true) {
          for (let i = 0; i < this.localBill.billServices.length; i++) {
            this.localBill.billServices[i].status = "notPaid"
          }
          for (let k = 0; k < this.localBill.billItems.length; k++) {
            this.localBill.billItems[k].status = "notPaid"
          }
        }

        let documentNumberFixed = undefined
        if (!!rtResponse && !!rtResponse.addInfo) {
          rtResponse.addInfo.zRepNumber = '' + rtResponse.addInfo.zRepNumber
          rtResponse.addInfo.fiscalReceiptNumber = '' + rtResponse.addInfo.fiscalReceiptNumber
          while (rtResponse.addInfo.zRepNumber.length < 4) {
            rtResponse.addInfo.zRepNumber = '0' + rtResponse.addInfo.zRepNumber
          }
          while (rtResponse.addInfo.fiscalReceiptNumber.length < 4) {
            rtResponse.addInfo.fiscalReceiptNumber = '0' + rtResponse.addInfo.fiscalReceiptNumber
          }
            if (!!rtResponse.addInfo.zRepNumber && rtResponse.addInfo.zRepNumber != 'undefined' 
              && !!rtResponse.addInfo.fiscalReceiptNumber && rtResponse.addInfo.fiscalReceiptNumber != 'undefined')
            documentNumberFixed = rtResponse.addInfo.zRepNumber + '-' + rtResponse.addInfo.fiscalReceiptNumber
        }

        let payingFunction
        !!this.partialPayment ? payingFunction = 'payPartial' : payingFunction = 'close'

        res = await billService[payingFunction](
          this.localBill,
          this.openedCashDesk,
          this.invoiceOrReceipt,
          {
            cashPaid: this.paidWithCash,
            cardPaid: this.paidWithCard,
            checkPaid: this.paidWithCheck,
            otherPaid: this.paidWithTickets,
            notPaid: this.notPaid,
            customerCardPaid: this.useCustomerCard ? billServiceCalculator.cardSubtract : 0,
            documentNumber: !!documentNumberFixed ? documentNumberFixed : undefined,
            usedPromos: !!billServiceCalculator.promoUsed ? [billServiceCalculator.promoUsed] : undefined,
            billEntities: this.providedEverything,
            promoRemaining: this.promoRemaining !== undefined && this.promoRemaining !== null ? this.promoRemaining : this.remaining != undefined && this.remaining != null ? this.remaining : undefined,
            cashDeskLog: this.cashDeskLog || cashDesk.cashDeskLog
          },
          this.tableId
        )
      } else if (this.invoiceOrReceipt == 'invoice') {
        let invoiceData = {
          ...this.invoiceData,
          lastname: this.invoiceData.companyNameOrLastname,
          amount: billServiceCalculator.total,
          status: 'pending',
          description: 'Generata automaticamente dalla cassa',
        }

        delete invoiceData.companyNameOrLastname

        res = await billService.close({
          ...this.localBill,
          invoiceData: invoiceData,
        },
          this.openedCashDesk,
          this.invoiceOrReceipt,
          {
            cashPaid: this.paidWithCash,
            cardPaid: this.paidWithCard,
            checkPaid: this.paidWithCheck,
            otherPaid: this.paidWithTickets,
            notPaid: this.notPaid,
            usedPromos: !!billServiceCalculator.promoUsed ? [billServiceCalculator.promoUsed] : undefined,
            promoRemaining: this.remaining != null && this.remaining != undefined ? this.remaining : undefined,
            cashDeskLog: this.cashDeskLog
          },
          this.tableId
        )
      }

      return res
    },

    // main method that pay and close the bill
    async pay() {
      try {
        this.billClosed = false
        this.dataRegistered = false
        this.invoicePrinted = false
        this.canCloseDialog = false
        this.canChangeStep = false

        if (Number((Number(this.paidWithCash) + (Number(this.paidWithCard) || 0) + (Number(this.paidWithTickets) || 0) + (Number(this.paidWithCheck) || 0)).toFixed(2)) > Number(this.totalValue)) {
          this.paidWithCash = Math.max(Number(this.totalValue) - (Number(this.paidWithCard) || 0) - (Number(this.paidWithTickets) || 0) - (Number(this.paidWithCheck) || 0), 0)
        }

        if (Number((Number(this.paidWithCard) + (Number(this.paidWithTickets) || 0) + (Number(this.paidWithCheck) || 0)).toFixed(2)) > Number((Number(this.totalValue) - (Number(this.paidWithCash) || 0)).toFixed(2))) {
          this.paidWithCard = Math.max(Number(this.totalValue) - (Number(this.paidWithTickets) || 0) - (Number(this.paidWithCheck) || 0), 0)
        }

        if (Number((Number(this.paidWithTickets) + (Number(this.paidWithCheck) || 0)).toFixed(2)) > Number((Number(this.totalValue) - (Number(this.paidWithCash) || 0) - (Number(this.paidWithCard) || 0)).toFixed(2))) {
          this.paidWithTickets = Math.max(Number(this.totalValue) - (Number(this.paidWithCheck) || 0), 0)
        }

        if (Number(this.paidWithCheck) > Number((Number(this.totalValue) - (Number(this.paidWithCash) || 0) - (Number(this.paidWithCard) || 0) - (Number(this.paidWithTickets) || 0)).toFixed(2))) {
          this.paidWithCheck = Number(this.totalValue)
        }

        const { notGhostedAnything, notGhostedItems, notGhostedServices } = this.calculateNotGhostedEntities()
        let paymentTypes = this.calculatePaymentTypes()

        try {
          let printerStatus = await Printer.getStatus()
        } catch (exception) {
          // KEEP LOG
          console.log(exception)

          this.$delegates.snackbar('Errore durante la comunicazione con la stampante')
          this.canCloseDialog = true
          this.billClosed = false
          return
        }

        this.dataRegistered = true

        let rtResponse
        try {
          rtResponse = await this.sendToRtPrinter({
            notGhostedAnything,
            notGhostedItems,
            notGhostedServices,
            paymentTypes
          })
        } catch (exception) {
          // logging the exception for debug purpose
          // KEEP LOG
          console.log(exception)

          if (String(exception).includes("URL") || String(exception).includes("protocol")) {
            this.$delegates.snackbar('Stampante non collegata / Indirizzo stampante errato', 0)
            this.canCloseDialog = true
            return
          }

          // if something goes wrong manually set the success to false
          rtResponse = {
            attributes: {
              success: 'false'
            }
          }
        }

        if (!!rtResponse && !!rtResponse.attributes && rtResponse.attributes.success == 'false') {
          this.$delegates.snackbar('Errore durante la stampa dello scontrino', 0)
          this.canCloseDialog = true
          return
        }


        let transaction
        try {
          transaction = await this.closeBill({
            rtResponse
          })
        } catch (exception) {
          // KEEP LOG
          console.log(exception)

          this.$delegates.snackbar('Errore durante la chiusura del conto')
          this.canCloseDialog = true
          this.billClosed = false
          return
        }

        if (this.useCustomerCard) {
          await customerCardService.transact(this.activeCard, this.openedCashDesk, - billServiceCalculator.cardSubtract)
        }

        if (!!transaction) {
          if (!!rtResponse) {
            let documentNumberFixed = undefined
            if (!!rtResponse && !!rtResponse.addInfo) {
              rtResponse.addInfo.zRepNumber = '' + rtResponse.addInfo.zRepNumber
              rtResponse.addInfo.fiscalReceiptNumber = '' + rtResponse.addInfo.fiscalReceiptNumber
              while (rtResponse.addInfo.zRepNumber.length < 4) {
                rtResponse.addInfo.zRepNumber = '0' + rtResponse.addInfo.zRepNumber
              }
              while (rtResponse.addInfo.fiscalReceiptNumber.length < 4) {
                rtResponse.addInfo.fiscalReceiptNumber = '0' + rtResponse.addInfo.fiscalReceiptNumber
              }
              if (!!rtResponse.addInfo.zRepNumber && rtResponse.addInfo.zRepNumber != 'undefined'
                && !!rtResponse.addInfo.fiscalReceiptNumber && rtResponse.addInfo.fiscalReceiptNumber != 'undefined')
                documentNumberFixed = rtResponse.addInfo.zRepNumber + '-' + rtResponse.addInfo.fiscalReceiptNumber

              billService.updateDocumentNumber(transaction.id, documentNumberFixed)
            }
          }

          let receiptModels = []

          try {
            let marketingEventsOfTheDay = await marketingEventsService.getTodayEvents({
              tool: 'receipt',
              status: 'approved',
              actionHasStarted: true,
              customerId: this.customer.id,
              //transactionId: transaction.id
            })

            let totalPaid = paymentTypes.reduce((acc, item) => {
              return acc + Number(item.amount)
            }, 0)


            marketingEventsOfTheDay.forEach((event) => {
              let ctrl = false
              if (!!event.actions.recipients.sendByFinancialService) {
                event.actions.recipients.sendByFinancialService.forEach((recipient) => {
                  switch (recipient) {
                    case '50-100':
                      if (totalPaid >= 50 && totalPaid <= 100)
                        ctrl = true
                      break;
                    case '100-200':
                      if (totalPaid >= 100 && totalPaid <= 200)
                        ctrl = true
                      break;
                    case '200-300':
                      if (totalPaid >= 200 && totalPaid <= 300)
                        ctrl = true
                      break;
                    case '300+':
                      if (totalPaid >= 300)
                        ctrl = true
                      break;
                  }
                })
              } else
                ctrl = true

              if (ctrl)
                receiptModels.push(event.actions.receiptModel)
            })

            if (receiptModels.length > 0)
              await Printer.marketingReport(receiptModels)
          } catch (exception) {
            console.log(exception)
          }
        }

        // don't need to wait
        try {
          this.sendSMS()
        } catch (error) {
          // logging the exception for debug purpose
          //KEEP LOG
          console.log('cannot send sms: ', error)
        }

        this.invoicePrinted = true



        this.canCloseDialog = true
        this.billClosed = true
        localStorage.removeItem('bill')
        setTimeout(this.closeDialog, 2000)
      } catch (err) {
        // KEEP LOG
        console.log(err)

        this.$delegates.snackbar("Si è verificato un errore generico");
      }
    },
  },
}